import axios from "axios";
import { regexDataSet } from "../consts/const.js";

// Helper function to make HTTP GET requests
export const httpGET = async (url, config = {}) => {
  // Prepend default protocol if not provided
  if (!regexDataSet.HTTP_REGEX.test(url)) {
    url = process.env.REACT_APP_API_URL + url;
  }

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error fetching data:", error);
    return { error: "Error fetching data" };
  }
};

// Helper function to make HTTP POST requests
export const httpPOST = async (url, data, config = {}) => {
  // Prepend default protocol if not provided
  if (!regexDataSet.HTTP_REGEX.test(url)) {
    url = process.env.REACT_APP_API_URL + url;
  }

  try {
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error posting data:", error);
    return { error: "Error posting data" };
  }
};

// Helper function to make HTTP PUT requests
export const httpPUT = async (url, data, config = {}) => {
  // Prepend default protocol if not provided
  if (!regexDataSet.HTTP_REGEX.test(url)) {
    url = process.REACT_APP_API_URL + url;
  }

  try {
    const response = await axios.put(url, data, config);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error updating data:", error);
    return { error: "Error updating data" };
  }
};

// Helper function to make HTTP DELETE requests
export const httpDELETE = async (url, config = {}) => {
  // Prepend default protocol if not provided
  if (!regexDataSet.HTTP_REGEX.test(url)) {
    url = process.env.REACT_APP_API_URL + url;
  }

  try {
    const response = await axios.delete(url, config);
    return response.data;
  } catch (error) {
    // Handle error
    console.error("Error deleting data:", error);
    return { error: "Error deleting data" };
  }
};
