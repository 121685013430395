export const messages = {
    "INVALID_CREDENTIALS":"Invalid Credentials",
    "PLEASE_ENTER_REQUIRED_FIELDS":"Please enter required fields",
    "PLEASE_ENTER_VALID_EMAIL":"Please enter valid email"
    
}
export const regexDataSet = {
    "EMAIL_REGEX" : /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    "HTTP_REGEX":/^https?:\/\//
}
export const apiEndPoints = {
    "LOGIN":"auth/login"
}