import React from "react";
import social from "../../../assets/project-images/images/social.svg";
import loginPattern from "../../../assets/project-images/patterns/login-pattern.svg";
import {
  apiEndPoints,
  messages,
  regexDataSet,
} from "../../../utils/consts/const.js";
import { httpPOST } from "../../../utils/common_helpers/api_helper.js";
import { useNavigate } from "react-router-dom";
export default function P_login() {
  const [loginFormData, setLoginFormData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [validation, setValidation] = React.useState();
  const navigator = useNavigate();
  const handleValue = (event) => {
    let { id, value } = event.target;
    let tempFormData = { ...loginFormData, [id]: value };
    setLoginFormData(tempFormData);
  };
  const loginValidation = () => {
    let { email, password } = loginFormData;
    setValidation();
    if (!email || !password) {
      setValidation(messages.PLEASE_ENTER_REQUIRED_FIELDS);
      return false;
    }
    if (!regexDataSet.EMAIL_REGEX.test(email)) {
      setValidation(messages.PLEASE_ENTER_VALID_EMAIL);
      return false;
    }
    return true;
  };
  const attemptToLogin = async () => {
    setIsLoading(true);
    if (loginValidation()) {
      let res = await httpPOST(apiEndPoints.LOGIN, loginFormData);
      if (res.token) {
        sessionStorage.setItem(process.env.AUTH_KEY, res.token);
        navigator("/dashboard");
      } else {
        setValidation(messages.INVALID_CREDENTIALS);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="h-screen w-full bg-[--palette-primary] text-white flex justify-center items-center  p-4 md:p-16 overflow-y-auto">
      <div className="max-w-3xl w-5/6   bg-white rounded-xl shadow-lg flex flex-col-reverse md:flex-row items-center">
        <div className="bg-blue-800 flex flex-col h-full rounded-xl  md:rounded-tr-none md:rounded-br-none p-4 w-full md:w-1/2 ">
          <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto  lg:py-0">
            <a
              href="#"
              className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
            >
              <img
                className="w-8 h-8 mr-2"
                src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
                alt="logo"
              />
              Zeus
            </a>
            <div className="w-full  rounded-lg   md:mt-0 sm:max-w-md xl:p-0 ">
              <div className=" space-y-4 md:space-y-6 sm:p-8">
                <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
                </h1>
                <p>{validation}</p>
                <div className="space-y-4 md:space-y-6" action="#">
                  <div>
                    <label
                      for="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Your email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="name@company.com"
                      required=""
                      onChange={handleValue}
                    />
                  </div>
                  <div>
                    <label
                      for="password"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      placeholder="••••••••"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      required=""
                      onChange={handleValue}
                    />
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="flex items-start"></div>
                    <a
                      href="#"
                      className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Forgot password?
                    </a>
                  </div>
                  <button
                  disabled={isLoading}
                    onClick={attemptToLogin}
                    className="w-full flex justify-center gap-4 items-center text-white bg-primary-600 hover:bg-primary-700  focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-[--palette-primary] "
                  >
                    Sign in{" "}
                    {isLoading && (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="w-4 h-4 text-gray-200 animate-spin border-none  fill-[--palette-primary]"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                  <p className="text-sm font-light text-gray-100 dark:text-gray-100">
                    Don’t have an account yet?{" "}
                    <a
                      href="#"
                      className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                    >
                      Sign up
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden md:block w-1/2 rounded-tr-xl rounded-br-xl">
          <div className="relative w-full p-8 md:p-16 ">
            <img
              src={loginPattern}
              className="absolute inset-0 w-full h-full object-contain"
              alt=""
            />
            <img
              src={social}
              className="relative inset-0 w-full h-2/4 object-cover  "
              style={{
                zIndex: 0,
              }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
