import React from "react";
import A_card from "../card/A_card";

export default function A_table() {
  return (
    <div>
      <A_card header={"Tenant Details"}>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Tenant name
                </th>
                <th scope="col" className="px-6 py-3">
                  URL
                </th>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Owner
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            
              <tr className="bg-white ">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                >
                  Jorden
                </th>
                <td className="px-6 py-4">jorden.oxlide.co.in</td>
                <td className="px-6 py-4">4b93d41e-2729-42f0-bd29-4a6c441dd8bb</td>
                <td className="px-6 py-4">Akash</td>
                <td className="px-6 py-4">
                  <a
                    href="#"
                    className="font-medium text-blue-600  hover:underline"
                  >
                    Edit
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </A_card>
    </div>
  );
}
