// ProtectedRoute.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "./utils/common_helpers/common";
import P_loading_full from "./components/pages/common_screens/loading_full";

const ProtectedRoute = ({ children, ...props }) => {
  let navigate = useNavigate();
  
  React.useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    }
  }, []);
  return (
    <>

      {isAuthenticated() ? (
        children
      ) : (
       <P_loading_full/>
      )}
    </>
  );
};

export default ProtectedRoute;
