import React from "react";
import A_table from "../../atoms/table/A_table";

export default function P_tenant_management() {
  return (
    <div>
      <A_table></A_table>
    </div>
  );
}
