import React from "react";
import Chart from "react-apexcharts";
import A_card from "../../atoms/card/A_card";

export default function P_dashboard() {
  // function generateData(startDate, count, range) {
  //   const data = [];
  //   const min = range.min;
  //   const max = range.max;
  //   const interval = (max - min) / count;

  //   for (let i = 0; i < count; i++) {
  //     const randomOffset = Math.random() * interval;
  //     const timestamp = startDate + i * 24 * 60 * 60 * 1000; // Increment by a day
  //     const value = min + i * interval + randomOffset;
  //     data.push({ timestamp, value });
  //   }

  //   return data;
  // }
  return (
    <div>
      <div className="grid grid-cols-12 gap-4">
        <A_card header={"Company Growth"}>
          <Chart
            {...{
              options: {
                chart: {},
                xaxis: {
                  categories: [
                    1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999,
                  ],
                },
              },
              series: [
                {
                  name: "series-1",
                  data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
                },
                {
                  name: "series-2",
                  data: [20, 30, 25, 60, 79, 10, 30, 71, 185],
                },
              ],
            }}
            type="bar"
          />
        </A_card>
        <A_card header={"Sales"}>
          <Chart
            {...{
              series: [44, 55, 41, 17, 15],
              options: {
                chart: {
                  type: "donut",
                },
                responsive: [
                  {
                    breakpoint: 480,
                    options: {
                      chart: {},
                      legend: {
                        position: "bottom",
                      },
                    },
                  },
                ],
              },
            }}
            type="donut"
          />
        </A_card>
        <A_card header={"Sales"}>
          {" "}
          <Chart
            {...{
              series: [
                {
                  name: "Series 1",
                  data: [80, 50, 30, 40, 100, 20],
                },
              ],
              options: {
                chart: {
                  type: "radar",
                },

                xaxis: {
                  categories: ["2011", "2012", "2013", "2014", "2015", "2016"],
                },
              },
            }}
            type="radar"
            height={350}
          />
        </A_card>
        <A_card header="Sales">
          {" "}
          <Chart
            {...{
              series: [
                {
                  name: "Bubble1",
                  data: [
                    22,
                    23,
                    31,
                    24,
                    45,
                    26,
                    57,
                    28,
                    19,
                    50,
                    ,
                    12,
                    16,
                    53,
                    13,
                    59,
                    36,
                    12,
                    12,
                    60,
                    3,
                  ],
                },
              ],
              options: {
                chart: {
                  type: "bubble",
                },
              },
            }}
          />
        </A_card>

        <A_card header={"Sales"}>
          {" "}
          <Chart
            {...{
              series: [
                {
                  name: "box",
                  type: "boxPlot",
                  data: [
                    {
                      x: new Date("2017-01-01").getTime(),
                      y: [54, 66, 69, 75, 88],
                    },
                    {
                      x: new Date("2018-01-01").getTime(),
                      y: [43, 65, 69, 76, 81],
                    },
                    {
                      x: new Date("2019-01-01").getTime(),
                      y: [31, 39, 45, 51, 59],
                    },
                    {
                      x: new Date("2020-01-01").getTime(),
                      y: [39, 46, 55, 65, 71],
                    },
                    {
                      x: new Date("2021-01-01").getTime(),
                      y: [29, 31, 35, 39, 44],
                    },
                  ],
                },
                {
                  name: "outliers",
                  type: "scatter",
                  data: [
                    {
                      x: new Date("2017-01-01").getTime(),
                      y: 32,
                    },
                    {
                      x: new Date("2018-01-01").getTime(),
                      y: 25,
                    },
                    {
                      x: new Date("2019-01-01").getTime(),
                      y: 64,
                    },
                    {
                      x: new Date("2020-01-01").getTime(),
                      y: 27,
                    },
                    {
                      x: new Date("2020-01-01").getTime(),
                      y: 78,
                    },
                    {
                      x: new Date("2021-01-01").getTime(),
                      y: 15,
                    },
                  ],
                },
              ],
              options: {
                chart: {
                  type: "boxPlot",
                  height: 350,
                },
                colors: ["#008FFB", "#FEB019"],
                title: {
                  text: "BoxPlot - Scatter Chart",
                  align: "left",
                },
                xaxis: {
                  type: "datetime",
                  tooltip: {
                    formatter: function (val) {
                      return new Date(val).getFullYear();
                    },
                  },
                },
                tooltip: {
                  shared: false,
                  intersect: true,
                },
              },
            }}
          />
        </A_card>
      </div>
    </div>
  );
}
