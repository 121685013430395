// auth.js
export const isAuthenticated = () => {
    // Your authentication logic here, for example:
    const token = sessionStorage.getItem(process.env.AUTH_KEY);
    return !!token; // Return true if the token exists, otherwise false
  };

  export const logout = ()=>{
    sessionStorage.removeItem(process.env.AUTH_KEY)
    
  }
  