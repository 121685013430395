import logo from "./assets/project-images/logo/logo.png";
import { colors } from "./utils/consts/colors";
import graphLite from "./assets/project-images/patterns/graph-lite.png";
import graphDark from "./assets/project-images/patterns/graph-dark.svg";
import wave from "./assets/project-images/patterns/wave.png";
import waveTop from "./assets/project-images/patterns/wave-top.png";
import waveBottom from "./assets/project-images/patterns/skill-wave-bottom.png";
import contactGraph from "./assets/project-images/patterns/contact-graph.png";
import pattern from "./assets/project-images/patterns/pattern.png";
import pattern2 from "./assets/project-images/patterns/pattern2.png";
import mailIcon from "./assets/project-images/patterns/mail-icon.png";
import personIcon from "./assets/project-images/patterns/person-icon.png";
import phoneIcon from "./assets/project-images/patterns/phone-icon.png";
import tick from "./assets/project-images/patterns/tick.png";
import appDev from "./assets/project-images/patterns/app-dev.png";
import webDev from "./assets/project-images/patterns/web-dev.png";
import code from "./assets/project-images/patterns/code.png";

// svg images
import SE from "./assets/project-images/patterns/SE.svg";
import snippet from "./assets/project-images/patterns/snippet.svg";
import mobileDev from "./assets/project-images/patterns/website.png";

import "./app.css";
import * as React from "react";
function App() {
  const words = [
    "Hello, World!",
    "Welcome to my website!",
    "I'm a software engineer with experience of multiple technologies",
    "Please go through my portfolio!",
  ];
  const [i, setI] = React.useState(0);
  const [j, setJ] = React.useState(0);
  const [currentWord, setCurrentWord] = React.useState(words[0]);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [typeWriterContent, setTypeWriterContent] = React.useState("");

  const type = () => {
    setCurrentWord(words[i]);
    if (isDeleting) {
      setTypeWriterContent(currentWord.substring(0, j - 1));
      setJ(j - 1);
      if (j === 0) {
        setIsDeleting(false);
        setI(+i + 1);
        setJ(+j + 1);
        if (i === words.length - 1) {
          setI(0);
        }
      }
    } else {
      setTypeWriterContent(currentWord.substring(0, +j + 1));
      let tempj = +j + 1;
      setJ(tempj);
      if (j === currentWord.length) {
        setIsDeleting(true);
        setJ(j - 1);
      }
    }
  };
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    console.log("toggle:", isDrawerOpen);
    setIsDrawerOpen(!isDrawerOpen);
  };

  React.useEffect(() => {
    setTimeout(type, 100);
  }, [typeWriterContent, isDeleting]);
  return (
    <>
      <header>
        <nav className="flex justify-between items-center px-8 py-4 md:py-0 shadow-md fixed w-full  bg-white z-50">
          <img
            draggable={false}
            src={logo}
            alt=""
            style={{ height: "2rem" }}
            className="logo"
          />
          <div className="hidden  md:flex services  gap-4 font-semibold w-4/6 justify-center border-gray-200 border  py-4">
            <a href="" className="item">
              Home
            </a>
            <a href="" className="item">
              About
            </a>
            <a href="" className="item">
              Experience
            </a>
            <a href="" className="item">
              Projects
            </a>
          </div>
          <div className="user hidden md:inline">
            <button
              style={{ backgroundColor: colors["palette-primary"] }}
              className="rounded-md px-4 py-1 text-white font-semibold"
            >
              Contact me
            </button>
          </div>

          <div className="text-center md:hidden">
            <button
              onClick={toggleDrawer}
              className="text-white font-medium rounded-lg text-sm  mb-2"
              type="button"
              data-drawer-target="drawer-example"
              data-drawer-show="drawer-example"
              aria-controls="drawer-example"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24"
                viewBox="0 -960 960 960"
                width="24"
              >
                <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
              </svg>
            </button>
          </div>
          {isDrawerOpen && (
            <div
              id="drawer-example"
              className="md:hidden fixed top-0 left-0 z-50 h-screen p-4 overflow-y-auto transition-transform bg-white w-80 dark:bg-gray-800"
              tabIndex="-1"
              aria-labelledby="drawer-label"
            >
              <h5
                id="drawer-label"
                className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"
              >
                <svg
                  className="w-4 h-4 me-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                Info
              </h5>
              <button
                type="button"
                data-drawer-hide="drawer-example"
                aria-controls="drawer-example"
                onClick={toggleDrawer}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close menu</span>
              </button>

              <div class="py-4 overflow-y-auto">
                <ul class="space-y-2 font-medium">
                <li>
                    <a
                      href="#"
                      class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <svg
                        class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                        <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                      </svg>
                      <span class="ms-3">Home</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <svg
                        class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                        <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                      </svg>
                      <span class="ms-3">Experience</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <svg
                        class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 21"
                      >
                        <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                        <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                      </svg>
                      <span class="ms-3">Service</span>
                    </a>
                  </li>
                  
                  <li>
                    <a
                      href="#"
                      class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                    >
                      <svg
                        class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                        <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                        <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                      </svg>
                      <span class="flex-1 ms-3 whitespace-nowrap">Contact Me</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </nav>
      </header>
      <main>
        <section className="">
          <div className="banner flex text-white justify-between items-center px-16 pt-24 flex-col md:flex-row">
            <div className="content w-full md:1/2 h-96  justify-center flex flex-col">
              <div className="title text-3xl font-bold mb-4">
                Hello, I'm Akash Chourasia, (Software Engineer)
              </div>
              <div className="subtitle font-semibold">
                : {typeWriterContent}
              </div>
            </div>
            <div className="relative w-full md:1/2  p-4 md:p-16 ">
              <img
                src={graphLite}
                className="absolute inset-0 w-full h-full object-contain"
                alt=""
              />
              <img
                src={SE}
                className="relative inset-0 w-full h-full object-cover  "
                style={{
                  zIndex: 0,
                }}
                alt=""
              />
            </div>
          </div>
          <img draggable={false} src={wave} style={{ width: "100%" }} alt="" />
        </section>

        <section className="px-4 lg:px-16 my-4 lg:my-16 flex justify-between items-center flex-col lg:flex-row gap-2 md:gap-16 ">
          <div className="flex gap-4 w-5/6 md:w-1/3 shadow-md px-4 py-2 rounded-md hover:-translate-y-4 justify-center">
            <img draggable={false} src={tick} alt="" />
            <div className="">
              <div className="font-semibold text-md text-[var(--palette-primary)] ">
                MERN Stack
              </div>
              <div className="text-sm text-gray-500">
                Experienced Full stack developer
              </div>
            </div>
          </div>
          <div className="flex gap-4 w-5/6 md:w-1/3 shadow-md px-4 py-2 rounded-md hover:-translate-y-4 justify-center">
            <img draggable={false} src={tick} alt="" />
            <div className="">
              <div className="font-semibold text-md text-[var(--palette-primary)]">
                Laravel Developer
              </div>
              <div className="text-sm text-gray-500">
                Experienced Full stack developer
              </div>
            </div>
          </div>
          <div className="flex gap-4 w-5/6 md:w-1/3 shadow-md px-4 py-2 rounded-md hover:-translate-y-4 justify-center">
            <img draggable={false} src={tick} alt="" />
            <div className="">
              <div className="font-semibold text-md text-[var(--palette-primary)]">
                Flutter Developer
              </div>
              <div className="text-sm text-gray-500">
                Experienced Full stack developer
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="section-content flex pt-10 px-8  flex-col items-center md:flex-row">
            <div className="vector-img w-full md:w-1/2 ">
              <div className="relative w-full p-16 ">
                <img
                  src={graphDark}
                  className="absolute inset-0 w-full h-full object-contain"
                  alt=""
                />
                <img
                  src={snippet}
                  className="relative inset-0 w-full h-2/4 object-cover hover:animate-bounce cursor-pointer "
                  style={{
                    zIndex: 0,
                  }}
                  alt=""
                />
              </div>
            </div>
            <div className="content w-full md:w-1/2 flex items-start">
              <div className="flex items-start -rotate-180 py-4 px-2 w-full  text-right">
                {" "}
                <span className="[writing-mode:vertical-lr] flex justify-start items-center gap-4 ">
                  <div
                    className="border-l border-gray-200"
                    style={{ height: "100px", borderLeft: "1px solid " }}
                  ></div>
                  about me{" "}
                </span>{" "}
              </div>
              <div className="">
                <div className="title font-semibold text-3xl ">
                  Professional Experience Summary
                </div>
                <div className="font-medium text-black ">
                  <div className="">
                    Hey, I'm a computer science engineer having Experience of
                    profession development of around 4 years,
                  </div>
                  <div className="">
                    I have worked on multiple project at my work as well as
                    freelancing,
                  </div>
                  <div className="">
                    In my profession journey I have learn lots of technology and
                    apply to solve real life problem of my clients.
                  </div>
                  <div className="">
                    Please visit my portfolio to know me more
                  </div>
                  <div className="pt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem odio aut officiis, numquam nostrum laudantium nam.
                    Temporibus iure quasi enim consectetur voluptatibus sint
                    ullam, quaerat, asperiores sapiente aliquid illo rerum.
                  </div>
                  <div className="pt-4">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem odio aut officiis, numquam nostrum laudantium nam.
                    Temporibus iure quasi enim consectetur voluptatibus sint
                    ullam, quaerat, asperiores sapiente aliquid illo rerum.
                  </div>
                </div>
                <button className="primary-button font-semibold px-4 py-2 mt-4 rounded-md ">
                  Know More
                </button>
              </div>
            </div>
          </div>

          {/* Career timeline */}

          <div className="px-8 md:px-24 my-24 items-center w-full justify-center">
            <ol className="items-center sm:flex justify-center">
              {/* freelancer */}
              <li className="relative mb-6 sm:mb-0">
                <div className="flex items-center justify-center">
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                  <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-[var(--palette-primary)] sm:ring-8 dark:ring-[var(--palette-primary)] shrink-0">
                    <svg
                      className="w-2.5 h-2.5 text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                </div>
                <div className="mt-3 text-center">
                  <h3 className="text-lg font-semibold text-[var(--palette-primary)] dark:text-[var(--palette-primary)]">
                    Freelancer
                  </h3>
                  <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Jan 2018 - May 2018
                  </time>
                  <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Developed the multiple projects for local clients and
                    vendors, such as management systems and e-commerce
                    applications
                  </p>
                </div>
              </li>

              {/* Bharti Infotech */}
              <li className="relative mb-6 sm:mb-0">
                <div className="flex items-center justify-center">
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                  <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-[var(--palette-primary)] sm:ring-8 dark:ring-[var(--palette-primary)] shrink-0">
                    <svg
                      className="w-2.5 h-2.5 text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                </div>
                <div className="mt-3 text-center">
                  <h3 className="text-lg font-semibold text-[var(--palette-primary)] dark:text-[var(--palette-primary)]">
                    Bharti InfoTech & Services
                  </h3>
                  <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    May 2018 - Nov 2018
                  </time>
                  <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Developed the multiple projects for local clients and
                    vendors, such as management systems and e-commerce
                    applications
                  </p>
                </div>
              </li>

              {/* Sysorex innovator Edge */}
              <li className="relative mb-6 sm:mb-0">
                <div className="flex items-center justify-center">
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                  <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-[var(--palette-primary)] sm:ring-8 dark:ring-[var(--palette-primary)] shrink-0">
                    <svg
                      className="w-2.5 h-2.5 text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                </div>
                <div className="mt-3 text-center">
                  <h3 className="text-lg font-semibold text-[var(--palette-primary)] dark:text-[var(--palette-primary)]">
                    Sysorex Innovator Edge
                  </h3>
                  <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Oct 2020 - Nov 2021
                  </time>
                  <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Developed the multiple projects for local clients and
                    vendors, such as management systems and e-commerce
                    applications
                  </p>
                </div>
              </li>

              {/* Equipped.ai */}
              <li className="relative mb-6 sm:mb-0">
                <div className="flex items-center justify-center">
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                  <div className="z-10 flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full ring-0 ring-white dark:bg-[var(--palette-primary)] sm:ring-8 dark:ring-[var(--palette-primary)] shrink-0">
                    <svg
                      className="w-2.5 h-2.5 text-white dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                    </svg>
                  </div>
                  <div className="hidden sm:flex w-full bg-gray-200 h-0.5 dark:bg-[var(--palette-primary)]"></div>
                </div>
                <div className="mt-3 text-center">
                  <h3 className="text-lg font-semibold text-[var(--palette-primary)] dark:text-[var(--palette-primary)]">
                    Equipped.Ai
                  </h3>
                  <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Nov 2021 - Present
                  </time>
                  <p className="text-base font-normal text-gray-500 dark:text-gray-400">
                    Developed the multiple projects for local clients and
                    vendors, such as management systems and e-commerce
                    applications
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </section>
        <section className=" mb-16">
          <img
            draggable={false}
            src={waveTop}
            style={{ width: "100%" }}
            alt=""
          />
          <div className=" text-white section-details flex justify-between px-8 md:px-24 pb-12  cursor-pointer flex-col md:flex-row">
            <div className="content w-full md:w-1/2 flex text-white ">
              <div className="flex items-end -rotate-180 py-4 px-2 ">
                <span className="[writing-mode:vertical-lr] flex justify-center items-center gap-4 ">
                  <div
                    className="border-l border-gray-200"
                    style={{ height: "100px", borderLeft: "1px solid " }}
                  ></div>
                  my skills
                </span>{" "}
              </div>
              <div className="">
                <div className=" font-semibold text-3xl text-white">
                  What my technical skills includes
                </div>
                <div className="">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Impedit repudiandae repellat tempora rem aliquid error totam
                  architecto culpa! Nisi iste ullam nemo minima totam dolorem,
                  aperiam quod voluptatibus iure aliquam.
                </div>
                <div className="font-medium text-white mt-6">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">React.JS</span>
                    <span>90%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">Node.JS</span>
                    <span>90%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">Laravel</span>
                    <span>80%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">MongoDB</span>
                    <span>80%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">MySQL</span>
                    <span>80%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">Python</span>
                    <span>90%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </div>
                <div className="font-medium text-white mt-2">
                  <div className="flex justify-between font-semibold text-sm">
                    <span className="font-semibold text-sm">Flutter</span>
                    <span>90%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full dark:bg-gray-400">
                    <div
                      className="bg-white text-xs font-medium text-black   text-center p-0.5 leading-none rounded-full"
                      style={{ width: "90%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 w-full md:w-1/3 justify-between mt-4">
              <div className="w-1/2">
                <div
                  className="bg-white shadow-lg rounded-lg flex flex-col text-center  justify-center items-center"
                  style={{ width: "100%", aspectRatio: 1, overflow: "hidden" }}
                >
                  <div className="border p-4 rounded-full bg-gray-50">
                    <img draggable={false} src={appDev} alt="" />
                  </div>
                  <div className="text-black font-semibold">
                    Mobile App Developer
                  </div>
                </div>
                <div
                  className="bg-white shadow-lg rounded-lg mt-2 flex flex-col text-center  justify-center items-center"
                  style={{ width: "100%", aspectRatio: 1, overflow: "hidden" }}
                >
                  <div className="border p-4 rounded-full bg-gray-50">
                    <img draggable={false} src={webDev} alt="" />
                  </div>
                  <div className="text-black font-semibold">Web Developer</div>
                </div>
              </div>
              <div className="w-1/2 mt-4">
                <div
                  className="bg-white shadow-lg rounded-lg flex flex-col text-center  justify-center items-center"
                  style={{ width: "100%", aspectRatio: 1, overflow: "hidden" }}
                >
                  <div className="border p-4 rounded-full bg-gray-50">
                    <img draggable={false} src={code} alt="" />
                  </div>
                  <div className="text-black font-semibold">
                    System Designing
                  </div>
                </div>
                <div
                  className="bg-black p-2 shadow-lg rounded-lg mt-2 flex flex-col text-center  justify-center items-center"
                  style={{ width: "100%", aspectRatio: 1, overflow: "hidden" }}
                >
                  <div className="text-gray-100 text-sm">
                    We believe direct collaboration with our client is essential
                    to bringing unique.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            draggable={false}
            src={waveBottom}
            alt=""
            style={{ width: "100%" }}
          />
        </section>
        <section className="px-8 md:px-24 flex gap-16 flex-col md:flex-row w-full justify-between items-center">
          <div className="w-full md:w-1/2">
            <div className="relative w-full p-8 md:p-16 ">
              <img
                src={contactGraph}
                className="absolute inset-0 w-full h-full object-contain"
                alt=""
              />
              <img
                src={mobileDev}
                className="relative inset-0 w-full h-2/4 object-cover  "
                style={{
                  zIndex: 0,
                }}
                alt=""
              />
            </div>
          </div>

          <div className="content  flex text-[var(--palette-primary)] w-full md:w-1/2 ">
            <div className="flex items-end -rotate-180 py-4 px-2 ">
              <span className="[writing-mode:vertical-lr] flex justify-center items-center gap-4 ">
                <div
                  className="border-l border-[var(--palette-primary)]"
                  style={{ height: "100px", borderLeft: "1px solid " }}
                ></div>
                contact me
              </span>{" "}
            </div>

            <form className="w-full">
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="email"
                  name="floating_email"
                  id="floating_email"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-[var(--palette-primary)] appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="floating_email"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Email address
                </label>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_first_name"
                    id="floating_first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_first_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    First name
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_last_name"
                    id="floating_last_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_last_name"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Last name
                  </label>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="floating_phone"
                    id="floating_phone"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_phone"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Phone number
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="text"
                    name="floating_company"
                    id="floating_company"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="floating_company"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Company (Ex. Google)
                  </label>
                </div>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label
                  for="message"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your message
                </label>
                <textarea
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400  dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Leave a comment..."
                ></textarea>
              </div>

              <button
                type="submit"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </form>
          </div>
        </section>
      </main>
      <footer className="bg-[var(--palette-primary)] mx-8 md:mx-24 my-16 rounded-3xl flex justify-center items-center flex-col text-center h-full p-4 md:p-16 ">
        <img src={graphLite} className="lite-pattern" alt="" />

        <img src={pattern} className="pattern" />
        <img src={pattern2} className="pattern2" />
        <div className="z-10">
          <div className="text-white font-bold text-5xl mb-4">Let's Talk</div>
          <div className="w-full flex justify-center flex-col md:flex-row gap-4 md:gap-16">
            <div className=" flex flex-col justify-center items-center text-white ">
              <div className="bg-white h-16 aspect-square flex justify-center p-4 rounded-full w-min items-center ">
                <img src={mailIcon} className="w-full" alt="" />
              </div>
              <div className="font-bold">Mail Me:</div>
              <div className="font-semibold text-gray-300">
                Ak.digiblade.cf@gmail.com
              </div>
            </div>
            <div className=" flex flex-col justify-center items-center text-white ">
              <div className="bg-white h-16 aspect-square flex justify-center p-4 rounded-full w-min items-center ">
                <img src={phoneIcon} className="w-full" alt="" />
              </div>
              <div className="font-bold">Phone Me:</div>
              <div className="font-semibold text-gray-300">+91-8871714381</div>
            </div>
            <div className=" flex flex-col justify-center items-center text-white ">
              <div className="bg-white h-16 aspect-square flex justify-center p-4 rounded-full w-min items-center ">
                <img src={personIcon} className="w-full" alt="" />
              </div>
              <div className="font-bold">Connect Me:</div>
              <div className="font-semibold text-gray-300">
                https://linkedin.com/12131412b1h13
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default App;
