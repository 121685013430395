import React from "react";

export default function A_card({ header, children, gridValues }) {
  return (
    <div
      className={`col-span-12 md:col-span-6  ${gridValues} w-full bg-white border border-gray-200 rounded-lg shadow `}
    >
      <div className=" p-4 font-semibold text-2xl  text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 ">
        {header}
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
}
