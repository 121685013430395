import React from "react";
import home from "../../../assets/project-images/patterns/home.png";
import login from "../../../assets/project-images/patterns/login.png";
import { Link } from "react-router-dom";
export default function P_404_NotFound() {
  return (
    <div className=" flex mt-24 flex-col items-center bg-white gap-8 w-1/3 mx-auto">
      <h1 className="text-8xl font-bold text-[--palette-primary]">404</h1>
      <div className="text-4xl font-semibold ">This page does not exist</div>
      <div className="text-lg font-semibold text-gray-400">
        The page are you looking for could not be found.
      </div>
      <div className="w-full divide-y-2">
        <div className="text-left text-lg font-semibold text-gray-400 pb-4">
          Continue with
        </div>
        <div className=" divide-y-2 ">
          <div className="flex items-center gap-4 py-4">
            <img src={home} alt="" className="w-8" />
            <div className="">
              <Link to="/" className="text-lg font-semibold">Home Page</Link>
              <div className="text-md font-semibold text-gray-400">
                Everything starts here
              </div>
            </div>
          </div>
          <Link to="/login" className="flex items-center gap-4 py-4">
            <img src={login} alt="" className="w-8" />
            <div className="">
              <div className="text-lg font-semibold">Login Page</div>
              <div className="text-md font-semibold text-gray-400">
                Everything connects here
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
