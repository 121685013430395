import React from "react";
import M_drawer_component from "../../molecules/drawer/M_drawer_content";

export default function O_drawer_component( props ) {
  let [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div>
      <M_drawer_component
        isDrawerOpen={isDrawerOpen}
        onDrawerClick={toggleDrawer}
        {...props}
      ></M_drawer_component>
    </div>
  );
}
