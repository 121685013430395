import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import P_login from "./components/pages/auth/login";
import ProtectedRoute from "./protected_routes";
import P_dashboard from "./components/pages/dashboard/P_dashboard";
import M_drawer_component from "./components/molecules/drawer/M_drawer_content";
import O_drawer_component from "./components/orginisms/common/O_drawer_component";
import O_main_content from "./components/orginisms/common/O_main_content";
import P_tenant_management from "./components/pages/dashboard/P_tenant_management";
import P_404_NotFound from "./components/pages/dashboard/P_404_NotFound";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <P_login />,
    errorElement: <h1>Something went wrong</h1>,
  },
  {
    path: "/dashboard",
    element: (
      <ProtectedRoute drawer={true}>
        <O_main_content breadCrumbs={["Dashboard"]}>
          <P_dashboard />
        </O_main_content>
      </ProtectedRoute>
    ),
    errorElement: <h1>Something went wrong</h1>,
  },
  {
    path: "/Tenant",
    element: (
      <ProtectedRoute drawer={true}>
        <O_main_content breadCrumbs={["Tenants"]}>
          <P_tenant_management />
        </O_main_content>
      </ProtectedRoute>
    ),
  },
  {
    path: "/",
    element: <App />,
    errorElement: <P_404_NotFound></P_404_NotFound>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
